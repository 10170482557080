import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/su/Personal/Projects/Playground/lottie-react/node_modules/gatsby-theme-docz/src/base/Layout.js";
import UseLottieExamples from "./UseLottieExamples";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "uselottie"
    }}>{`useLottie`}</h1>
    <h2 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h2>
    <UseLottieExamples mdxType="UseLottieExamples" />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useLottie } from "lottie-react";
import groovyWalkAnimation from "./groovyWalk.json";

const style = {
  height: 300,
};

const Example = () => {
  const options = {
    animationData: groovyWalkAnimation,
    loop: true,
    autoplay: true,
  };

  const { View } = useLottie(options, style);

  return View;
};

export default Example;
`}</code></pre>
    <h2 {...{
      "id": "params"
    }}>{`Params`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Subset of the lottie-web options`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options.animationData`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A JSON Object with the exported animation data`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options.loop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}{`|`}{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set it to true for infinite amount of loops, or pass a number to specify how many times should the last segment played be looped (`}<a parentName="td" {...{
              "href": "https://github.com/airbnb/lottie-web/issues/1450"
            }}>{`More info`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options.autoplay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`boolean`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If set to true, animation will play as soon as it's loaded`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`options.initialSegment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`array`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Expects an array of length 2. First value is the initial frame, second value is the final frame. If this is set, the animation will start at this position in time instead of the exported value from AE`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`style`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Style object that applies to the animation wrapper (which is a `}<inlineCode parentName="td">{`div`}</inlineCode>{`)`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.View`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React.Element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.play`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.stop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.pause`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.setSpeed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.goToAndStop`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.goToAndPlay`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.setDirection`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.playSegments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.setSubframe`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.getDuration`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Lottie.destroy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`method`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      